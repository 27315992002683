<template>
  <CompGoodTable
    ref-specify="CompGoodTableFormTugBoat"
    :columns="columns"
    :resolve-fetch-response="resolveFetchResponse"
    :resolve-fetch-payload="resolveFetchPayload"
    :there-is-group="false"
    :dateHumanReadAble="dateHumanReadAble"
    :disable-create-data="true"
  />
</template>

<script>
import moment from 'moment'
import CompGoodTable from '@views/pages/components/CompGoodTable.vue'

export default {
  name: 'RiverRouteTugBoatTable',
  components: {
    CompGoodTable
  },
  data() {
    return {
      columns: [
        {
          label: 'Status',
          field: 'ftb.status'
          // filterOptions: {
          //   enabled: true,
          //   placeholder: 'Cari Waktu Selesai'
          // },
        },
        {
          label: 'Kapal',
          field: 'ftb.kapal'
          // filterOptions: {
          //   enabled: true,
          //   placeholder: 'Cari Waktu Selesai'
          // },
        },
        {
          label: 'Keterangan',
          field: 'ftb.keterangan'
          // filterOptions: {
          //   enabled: true,
          //   placeholder: 'Cari Waktu Selesai'
          // },
        },
        // {
        //   label: 'Waktu Terakhir Kapal Sandar',
        //   field: 'tug_sandar_start_time',
        //   formatFn: this.dateHumanReadAble
        // },
        // {
        //   label: 'Waktu Terakhir Kapal Berangkat',
        //   field: 'tug_sandar_end_time',
        //   formatFn: this.dateHumanReadAble
        // }
        {
          label: 'Details',
          field: 'ftb.action'
        }
      ]
    }
  },
  methods: {
    moment,
    resolveFetchPayload() {
      return {
        API_SAL: 'form_tug_boat',
        serverParams: {
          // columnFilters: {}, // already defined on core system
          // searchTerm: '', // already defined on core system
          sort: {
            field: 'created_at',
            type: 'desc'
          },
          // page: 1, // already defined on core system
          perPage: 7 // override
        }
      }
    },
    dateHumanReadAble(value) {
      const format = moment(value).format('D MMMM YYYY - hh:mm A')
      if (format === 'Invalid date') {
        return 'Belum Diisi ❗️'
      }
      return format
    },
    resolveFetchResponse(response) {
      return response.data.form_tug_boats
    }
  }
}
</script>
