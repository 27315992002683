var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CompGoodTable', {
    attrs: {
      "ref-specify": "CompGoodTableFormTugBoat",
      "columns": _vm.columns,
      "resolve-fetch-response": _vm.resolveFetchResponse,
      "resolve-fetch-payload": _vm.resolveFetchPayload,
      "there-is-group": false,
      "dateHumanReadAble": _vm.dateHumanReadAble,
      "disable-create-data": true
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }